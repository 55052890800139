import React, { Component } from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";

let TeamContent = [
   
    // {
    //     images: '03',
    //     title: 'O. Cengiz',
    //     designation: 'Founder',
    //     socialNetwork: [
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: 'https://linkedin.com/in/oguzhan-cengiz'
    //         }
    //     ]
    // },
    {
        images: '02',
        title: 'C. Pinarci',
        designation: 'Sales and Customer Support Manager',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/cihanpinarci'
            },
        ]
    },
    {
        images: '01',
        title: 'A. Demokratova',
        designation: 'VP of Operations',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/alina-demokratova'
            }
        ]
    },
    // {
    //     images: '04',
    //     title: 'U. Sarıtaş',
    //     designation: 'Sales & CS Associate',
    //     socialNetwork: [
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: 'https://www.linkedin.com/in/u%C4%9Fur-berk-sar%C4%B1ta%C5%9F-43273b178/'
    //         }
    //     ]
    // }
    // {
    //     images: '06',
    //     title: 'Jone Due',
    //     designation: 'Sr. Web Developer',
    //     socialNetwork: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaTwitter />,
    //             url: '#'
    //         },
    //     ]
    // },
    // {
    //     images: '05',
    //     title: 'Fatima Asrafi',
    //     designation: 'Front-end Engineer',
    //     socialNetwork: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: '#'
    //         },
    //     ]
    // },
    // {
    //     images: '12',
    //     title: 'Al-Amin Bali',
    //     designation: 'Sr. Graphic Designer',
    //     socialNetwork: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaLinkedinIn />,
    //             url: '#'
    //         },
    //         {
    //             icon: <FaTwitter />,
    //             url: '#'
    //         },
    //     ]
    // }
];


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/team-${value.images}.jpg`} alt="Blog Images"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`} target="_blank">{social.icon}</a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;