import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Web Development (Frontend & Backend), Programs & Libraries',
        description: '                                        '
    },
    {
        icon: <FiLayers />,
        title: 'Blockchain and Cryptocurrency Solutions',
        description: '                                        '
    },
    { 
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: '                                        '
    },
    {
        icon: <FiUsers />,
        title: 'UI/UX Design',
        description: '                                        '
    },
    { 
        icon: <FiMonitor />,
        title: 'System Administration',
        description: '                                        '
    },
    {
        icon: <FiUsers />,
        title: 'IT Consultancy',
        description: '                                        '
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/* <a href="/service-details"> */}
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            {/* </a> */}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
